<template>
  <lidaPage
  ref="lidaPage"
    :columns="tableHead"
    :queryInfo="{isDistributionInfo:1,...queryInfo}"
    :query="purchasePageByDis"
    :formColumns="formColumns"
    subTitle="总订单数"
	:tableRowClassName="tableRowClassName"
  >
    <template #inquiryNo="scope">
      <!-- <div>{{scope.row.inquiryNo}}{{scope.row.undoApplyUserId==userId?}}</div> -->
      <div>
        <span>{{ scope.row.inquiryNo }}</span>
        <el-tag
          style="margin-left:10px"
          size="mini"
          v-if="scope.row.undoAudit === 'IN_PASS'"
          >{{
            scope.row.undoAudit == "IN_PASS"
              ? scope.row.undoApplyUserId == userId
                ? "作废待审核"
                : "作废申请中"
              : scope.row.undoAudit == "PASS"
              ? "同意(买方审核) "
              : scope.row.undoAudit == "UN_PASS"
              ? "不同意 (买方审核)"
              : ""
          }}</el-tag
        >
      </div>
    </template>
    <template #documentStatus="scope">
      <div>{{ documentState[scope.row.documentStatus] }}</div>
    </template>
    <template #purchaseAmt="scope">
      <div>￥{{ scope.row.purchaseAmt }}</div>
    </template>
    <template #operation>
      <lida-table-column label="操作" width="100" fixed="right">
        <template #default="scope">
          <el-button @click="commands(scope.row)" type="text">查看</el-button>
          <el-button
            type="text"
            v-if="scope.row.undoFlag == '0'"
            @click="remove(scope.row)"
            style="color: #D84146;cursor: pointer;"
            >作废</el-button
          >
          <el-button
            type="text"
            v-if="scope.row.undoFlag == '1'"
            @click="deleteRow(scope.row)"
            style="color: #D84146;cursor: pointer;"
            >删除</el-button
          >
        </template>
      </lida-table-column>
    </template>
  </lidaPage>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      userId: JSON.parse(localStorage.getItem("userInfo")).userId,
      tableHead: [
        {
          label: "询价单号",
          prop: "inquiryNo",
          width: "260",
        },
        {
          label: "采购单号",
          prop: "documentNumber",
        },
        {
          label: "单据状态",
          prop: "documentStatus",
        },
        {
          label: "客户名称",
          prop: "buyerName",
        },
        {
          label: "联系人手机号",
          prop: "buyerPhone",
        },
        {
          label: "合同品牌",
          prop: "purchaseBrands",
        },
        {
          label: "合同数量",
          prop: "purchaseQty",
        },
        {
          label: "合同总金额",
          prop: "purchaseAmt",
        },
        {
          label: "采购公司",
          prop: "buyerEnterpriseName",
        },
        {
          label: "项目名称",
          prop: "projectName",
        },
      ],
      queryInfo: {
        type: "1",
      },
    };
  },
  methods: {
    ...mapActions("distributionMudule", [
      "purchasePageByDis", //获取订单列表
    ]),
    ...mapActions("purchaseMudule", [
      "purchaseReq",
      "purchaseDelete",
      "purchaseUndo",
    ]),
	tableRowClassName({row, rowIndex}) {
		if(row.undoFlag=='1'){
			 return 'warning-row';
		}
        return '';
      },
    commands(row) {
      //操作的点击事件
      // this.$router.push({
      // 	path: '/mysale/orderDetails',
      // 	query: {
      // 		id: row.documentId, //订单id
      // 		inquiryId: row.inquiryId, //询价单id
      // 	}
      // })
      this.$router.push({
        path: "/mysale/xinOrderDetails",
        query: {
          id: row.documentId, //订单id
          inquiryId: row.inquiryId, //询价单id
        },
      });
    },
    remove(v) {
      this.purchaseUndo(v.documentId).then((res) => {
        if (res && res.status == 200) {
          v.disabled = true;
          this.$message({
            message: "作废成功",
            type: "success",
          });
          this.$refs.getData()
        } else {
          this.$message.error("作废失败");
        }
      });
    },
    deleteRow(v) {
      this.purchaseDelete(v.documentId).then((res) => {
        // console.log(res);
        if (res.code == "0") {
          this.$message({
            message: "删除成功",
            type: "success",
          });
		   this.$refs.getData()
        } else {
          this.$message.error("删除失败");
        }
      });
    },
  },
  computed: {
    ...mapState("purchaseMudule", [
      "documentState",
      "orderStatus", //订单的筛选状态
    ]),
    formColumns() {
      return [
        { type: "date", prop: "time", label: "创建日期" },
        { type: "input", prop: "wd", label: "综合搜索" },
        {
          type: "select",
          prop: "documentStatus",
          label: "订单状态",
          options: this.orderStatus,
          optionsLabel: "title",
          optionsValue: "type",
        },
      ];
    },
  },
};
</script>
<style lang="less" scoped>
.line {
  background: #f2f5fa;
  height: 10px;
  width: calc(100% + 20px);
  position: relative;
  left: -10px;
}
</style>
